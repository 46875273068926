import { useState, useCallback } from "react";

function useDisclosure(initialValue = false): UseDisclosureReturn {
  const [opened, setOpened] = useState(initialValue);

  const open = useCallback(() => setOpened(true), []);
  const close = useCallback(() => setOpened(false), []);
  const toggle = useCallback(() => setOpened((prev) => !prev), []);

  return [opened, { open, close, toggle }];
}

export default useDisclosure;
type UseDisclosureReturn = [
  boolean, // opened state
  {
    open: () => void;
    close: () => void;
    toggle: () => void;
  }
];
