import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { commonNavigationLinks, commonTitles } from "../options/IOption";

function useNavigation(url) {
  const translation = (key) => t(`navigation.loyacStaff.${key}`);
  const { t } = useTranslation();

  const staff = useSelector((state) => state.rootAuth?.loyacStaff);

  const department = staff?.department;
  const isLSV = department === "LSV";
  const isProfDevDep = department === "Professional Development Department";
  const isMonitorAndEvalDep =
    department === "Monitoring & Evaluation Department";
  const isViewOnly = staff?.view_only;

  const staffDashboardLinks = [
    {
      title: "Registration Summary",
      to: "/staff/dashboard?status=&coreArea=&month=&year=2024",
      value: "staff/dashboard",
    },
  ];
  const importantLinks = [
    {
      title: "Human Resources",
      to: "https://drive.google.com/drive/folders/1aA79ELN7WMTCLdY481UVH7xxcUC0ZeXq",
      value: "newTab/human/resources",
    },
    {
      title: "Communications",
      to: "https://drive.google.com/drive/folders/1WjmNuiLysYiCyeH6LwXbrbKJo_umbnBr",
      value: "newTab/communication/department",
    },
  ];
  const loyacStaff = [
    {
      title: t("auth.dashboard"),
      to: "/staff/dashboard",
    },
    ...(!isMonitorAndEvalDep && !isViewOnly
      ? [
          {
            title: translation("createNew"),
            isChildren: true,
            children: [
              commonTitles(t, "/create-update/program/").publicProgram,
              commonTitles(t, "/create/program/").publicProgram,
              // commonTitles(t, "/activities/add/").exclusiveProgram, TODO: depracated exclusive program will be moved to programs
              commonTitles(t, "/initiative/add/").lsvInitiative,
            ],
          },
        ]
      : []),
    commonNavigationLinks(t).programs,
    {
      title: translation("lists"),
      isChildren: true,
      children: [
        ...(isLSV
          ? [
              {
                title: translation("locationsList"),
                to: "/locations/",
              },
              {
                title: translation("lsvUshersList"),
                to: "/ushers/",
              },
              {
                title: translation("initiativesManagementList"),
                to: "/initiatives/list/manage/",
              },
              {
                title: translation("levelsList"),
                to: "/levels/",
              },
              {
                title: translation("tagsList"),
                to: "/tags/",
              },
              {
                title: translation("guideStatusList"),
                to: "/guide_status/",
              },
            ]
          : [
              {
                title: translation("registrationLists"),
                to: "/registrations/",
              },
              { title: translation("membersList"), to: "/applicants/" },
              { title: translation("supervisorsList"), to: "/supervisors/" },
              { title: t("pages.coreProgram.title"), to: "/core/programs/" },
              {
                title: t("pages.programDetails.staff.applicantCredits"),
                to: "/credits/",
              },

              { title: translation("orders"), to: "/orders/" },
            ]),
        {
          title: "Manual Hours",
          to: "/staff/manual-hours",
        },
        ...(isProfDevDep || isViewOnly
          ? [
              commonNavigationLinks(t).requestsList,
              commonNavigationLinks(t).invitationsList,
            ]
          : []),

        ...(department === "Community Service Department"
          ? [
              commonTitles(t, "/volunteer_requests").requestsList,
              {
                title: translation("communityServiceCentersList"),
                to: "/community_center/list/",
              },
              {
                title: translation("invitationsList"),
                to: "/community_center/invitations/",
              },
              {
                title: translation("reports"),
                to: "/smj_reports/",
              },
            ]
          : []),
      ],
    },
    ...(isProfDevDep
      ? [
          {
            title: translation("reports"),
            to: "/reports",
          },
        ]
      : []),
    ...(!isLSV ? [commonNavigationLinks(t).commonThreads] : []),

    // {
    //   title: "Events",
    //   to: "/staff/events/",
    // },
  ];

  const tempEvents = [
    {
      title: "Events",
      to: "/staff/events/",
    },
  ];

  return { loyacStaff, tempEvents, staffDashboardLinks, importantLinks };
}

export default useNavigation;
