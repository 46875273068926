import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonNavigation from "../CommonNavigation";
import {
  NavbarOptionsNode,
  commonNavigationLinks,
  commonTitles,
} from "./IOption";

function UsherOptions() {
  const usherObj = useSelector((state: any) => state.rootUshers.usherObj);
  const { t } = useTranslation();

  const data: NavbarOptionsNode[] = [
    commonNavigationLinks(t).programs,
    commonTitles(t, `/applicant/${usherObj?.user}/history/?defaultTabKey=3`)
      .history,
  ];
  return <CommonNavigation data={data} />;
}

export default UsherOptions;
