import * as actionTypes from "./actionTypes";
import instance from "./instance";

// Actions
import { apiErrorCatcher } from "redux/slices/common-action-utils";

export const setStaff = (staff) => ({
  type: actionTypes.SET_STAFF,
  payload: staff,
});

export const getStaff = (userID) => async (dispatch) => {
  try {
    const res = await instance.get(`/staff/${userID}/`);
    const staff = res.data;

    dispatch(setStaff(staff));
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const updateStaff = (userID, staffData, history) => async (dispatch) => {
  try {
    let formData = new FormData();
    Object.keys(staffData).forEach((key) => {
      formData.append(key, staffData[key]);
    });
    const res = await instance.put(`/staff/${userID}/`, formData);

    const response = res.data;
    const staff = response.staff.data;

    dispatch(setStaff(staff));

    if (history) history.push(`/staff/${userID}/profile/`);
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};

export const getDashboardApplicantsData = (params) => async () => {
  try {
    const { data } = await instance.get(
      `/staff/dashboard/applicants?${params}`
    );
    return data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const getDashboardApplicantsAgeData = (params) => async () => {
  try {
    const { data } = await instance.get(
      `/staff/dashboard/applicants-by-age?${params}`
    );
    return data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const getProgramYears = () => async () => {
  try {
    const { data } = await instance.get(`/program-years`);
    return data;
  } catch (error) {
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
export const getPgMonthsRegData = (pgId, search) => async (dispatch) => {
  try {
    if (pgId) {
      const { data } = await instance.get(
        `/staff/dashboard/registrations-by-months/${pgId}/${search}`
      );
      return data;
    }
  } catch (error) {
    apiErrorCatcher(error, dispatch);
    error.response ? console.error(error.response.data) : console.error(error);
  }
};
