import axios from "axios";
import Cookies from "js-cookie";
import { logoutButton, setAuthTokens } from "redux/slices/common-action-utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_DOMAIN,
  // baseURL: "https://admin.loyac.org/api/",
});

export const refreshAccessToken = async () => {
  const refreshToken = Cookies.get("refreshToken");

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  const response = await instance.post("token/refresh/", {
    refresh: refreshToken,
  });
  const { access, refresh } = response.data;

  setAuthTokens(access, refresh);

  // // Update the access token in cookies
  // Cookies.set("accessToken", accessToken, { expires: 1 / 48 }); // Update with new expiry
  return access;
};

// Add request interceptor
instance.interceptors.request.use(
  async (config) => {
    const accessToken = Cookies.get("accessToken");
    const sessionToken = Cookies.get("sessionToken");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (sessionToken) {
      config.headers["X-Session-Token"] = sessionToken;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

// Add request interceptor
instance.interceptors.response.use(
  async (config) => {
    return config;
  },
  async (error) => {
    const status = ((error || {}).response || {}).status;
    const originalRequest = error.config;

    const response = error?.response;

    if (status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Prevent infinite loops

      // This should be updated as we started using django Allauth
      // some of it returns 401, 410 which is still valid
      if (!response?.config?.url?.includes("allauth/")) {
        if (response?.config?.url?.includes("token/refresh/")) {
          logoutButton("/accounts/?logout=true");
          return Promise.reject(error);
        }
        try {
          const newAccessToken = await refreshAccessToken();
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return instance(originalRequest); // Retry the original request
        } catch (refreshError) {
          // Handle refresh token errors (e.g., redirect to login)
          logoutButton("/accounts/?logout=true");
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance;

export async function httpRequestWithAuth(url, method, data, params, headers) {
  let feedback;
  try {
    feedback = await instance(url, {
      method: method,
      data,
      params,
      headers,
    })
      .then((res) => {
        return { status: true, data: res.data };
      })
      .catch((error) => {
        return {
          status: false,
          error_feedback: error.response.data || {
            non_field_errors: "Ops, Something went wrong",
          },
        };
      });
  } catch (e) {
    feedback = {
      status: false,
      fatal_error: true,
      msg: "Something went wrong, try again",
      error_feedback: { non_field_errors: "Ops, Something went wrong" },
    };
  }

  return feedback;
}

export async function httpRequestWithoutAuth(
  url,
  method,
  data,
  params,
  headers = {}
) {
  let feedback;
  try {
    feedback = await instance(url, {
      method: method,
      data,
      params,
      headers,
    })
      .then((res) => {
        return { status: true, msg: res.statusText, data: res.data };
      })
      .catch((error) => {
        return {
          status: false,
          msg: error.response.status,
          error_feedback: error.response.data || {
            non_field_errors: "Ops, Something went wrong",
          },
        };
      });
  } catch (e) {
    feedback = {
      status: false,
      fatal_error: true,
      msg: "Something went wrong, try again",
      error_feedback: { non_field_errors: "Ops, Something went wrong" },
    };
  }

  return feedback;
}
