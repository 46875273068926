import { gql } from "@apollo/client";
import { TProgram, TProgramMonth, TProgramPricing } from "types/program";

// Get program with limited data
export const GET_PROGRAM_LIMITED = gql`
  query GetProgram($id: ID!) {
    # Define the variable $id and its type
    program(id: $id) {
      # Use the variable $id in the query
      name
      arabicName
      allowedNationality
      programType
      pk
      id
      fees
      pricing {
        edges {
          node {
            price
            pk
            title
            isCustom
          }
        }
      }
      paymentOption
      platform
      preProgramQuestions
      deactivateApplicationFees
      isPublished
      months {
        pk
        id
        month
        startingDate
        endingDate
        registrationClosed
      }
      termsAndConditions
      ageFrom
      ageTo
      startingDate
    }
  }
`;

//program on payment form
export const GET_PROGRAM_PAYMENT_FORM = gql`
  query GetProgramPaymentForm($id: ID!) {
    # Define the variable $id and its type
    program(id: $id) {
      # Use the variable $id in the query
      name
      departmentName
      programType
      pk
      id
      fees
      discount
      discountDeadline
      hasActiveCoupons
      deactivateApplicationFees
      applicationFees
      paymentOption
    }
  }
`;

export const GET_PUBLIC_PROGRAMS = gql`
  query GetPublicPrograms(
    $first: Int
    $after: String
    $orderBy: String
    $ages: String
    $affiliates: String
    $languages: String
    $search: String
    $months: String
    $year: String
    $statuses: String
    $volunteer: Boolean
    $coreAreas: String
    $supervisorCode: String
    $offset: Int
  ) {
    programs(
      first: $first
      after: $after
      orderBy: $orderBy
      ages: $ages
      affiliates: $affiliates
      languages: $languages
      search: $search
      months: $months
      year: $year
      statuses: $statuses
      volunteer: $volunteer
      coreAreas: $coreAreas
      supervisorCode: $supervisorCode
      offset: $offset
    ) {
      totalCount
      edgeCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }

      edges {
        node {
          name
          arabicName
          noStyleDescription
          noStyleArabicDescription
          arabicImage
          image
          departmentName
          startingDate
          endingDate
          limit
          programType
          registrationClosed
          pk
          id
          ageFrom
          ageTo
          activity {
            pk
          }
          initiative {
            pk
          }
          isClosed
          isRegistered
          isUpcoming
          isPublished
          firstItem
        }
      }
    }
  }
`;

export const GET_TRAINERS = gql`
  query GetActiveTraners($isActive: Boolean) {
    trainers(isActive: $isActive) {
      edgeCount
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          pk
          id
          name
          isActive
        }
      }
    }
  }
`;

export const MUTATION_TRAINER = gql`
  mutation CreateUpdateTrainer($name: String!, $isActive: Boolean!, $id: ID) {
    createUpdateTrainer(input: { name: $name, isActive: $isActive, id: $id }) {
      obj {
        pk
        id
        name
        isActive
      }
      errors {
        messages
      }
    }
  }
`;

export const DELETE_TRAINER = gql`
  mutation DeleteTrainer($id: ID!) {
    deleteTrainer(id: $id) {
      deleted
    }
  }
`;

export const GET_UPCOMING_INTERNSHIP_PROGRAMS = gql`
  query GetUpcomingInternshipPrograms($upcomingInternshipPrograms: Boolean!) {
    programs(upcomingInternshipPrograms: $upcomingInternshipPrograms) {
      edges {
        node {
          pk
          id
          name
          phase
          startingDate
          termsAndConditions
          ageFrom
          ageTo
        }
      }
    }
  }
`;

export const GET_PROGRAM_ATTENDANCE = gql`
  query GetProgram($id: ID!) {
    # Define the variable $id and its type
    program(id: $id) {
      name
      startingDate
      endingDate
      startingTime
      endingTime
      programType
      months {
        id
      }
      differentAttendanceSchedule
      attendance {
        edges {
          node {
            status
            date
            timeIn
            timeOut
            comment
            applicant {
              id
              pk
            }
          }
        }
      }
      registrations {
        edges {
          node {
            status
            assignedCompany
            assignedBranch
            assignedDepartment
            monthChoice {
              month
              startingDate
            }
            applicant {
              id
              pk
              firstName
              lastName
              user {
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;

// types
export type TGetProgramLimited = {
  pk: number;
  pricing: {
    edges: {
      nodes: TProgramPricing[];
    };
  };
  months: TProgramMonth[];
} & TProgram;
export const GET_CORE_PROGRAMS = gql`
  query {
    corePrograms {
      edges {
        node {
          title
        }
      }
    }
  }
`;

export const GET_STAFF_DASHBOARD_REGISTRATIONS_DATA = gql`
  query ProgramNode(
    $year: String!
    $selectedMonths: String!
    $status: String!
    $coreArea: String!
    $programName: String!
    $affiliate: String!
    $registrationFees: String!
    $programFees: String!
    $smjMonth: String!
    $staffDashboard: Boolean!
  ) {
    programs(
      year: $year
      selectedMonths: $selectedMonths
      status: $status
      coreArea: $coreArea
      programName: $programName
      affiliate: $affiliate
      programFees: $programFees
      registrationFees: $registrationFees
      smjMonth: $smjMonth
      staffDashboard: $staffDashboard
    ) {
      totalCount
      edges {
        node {
          pk
          name
          applicantsCount
          startingDate
          programFeesPaidCount
          registrationFeesPaidCount
          fees
          departmentName
          target
          targetCount
          months {
            pk
            month
          }
        }
      }
    }
  }
`;

export const GET_STAFF_DASHBOARD_PROGRAMS_LIST = gql`
  query ProgramsList {
    programs(staffDashboard: true) {
      edges {
        node {
          name
          pk
          programType
          months {
            pk
            month
          }
        }
      }
    }
  }
`;
