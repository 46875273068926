import { Box } from "@mui/material";
type Props = {
  color: string;
  width: string;
  height: string;
};
const Logo = ({ color, width, height, ...props }: Props) => {
  let image = "loyac-logo";
  if (color === "white") {
    image = "loyac-logo-white";
  }
  return (
    <Box {...props}>
      <a href="https://www.loyac.org/default_en.aspx">
        <img
          src={`/icons/${image}.png`}
          alt="Loyac logo"
          title="Main website"
          width={width ?? "80px"}
        />
      </a>
    </Box>
  );
};

export default Logo;
