import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { HttpLink } from "@apollo/client/link/http";
import Cookies from "js-cookie";
import { onError } from "@apollo/client/link/error";
import { refreshAccessToken } from "redux/actions/instance";
import { Observable } from "@apollo/client";

const promiseToObservable = (promise: any) =>
  new Observable((subscriber: any) => {
    promise.then(
      (value: any) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err: any) => subscriber.error(err)
    );
    return subscriber; // this line can removed, as per next comment
  });

const httpLink = new HttpLink({
  // uri: "https://admin.loyac.org/graphql/",
  uri: process.env.REACT_APP_BACKEND_DOMAIN_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  const accessToken = Cookies.get("accessToken"); // Get the access token from cookies
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

// // Error handling link
const errorLink = onError(
  //@ts-ignore
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions.code === "UNAUTHENTICATED") {
          return promiseToObservable(refreshAccessToken()).flatMap(() =>
            forward(operation)
          );
        }
      }
    }

    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  }
);

// Apollo settings
export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
});
